<template>
  <v-layout shrink align-center>
    <div class="page-info">
      {{ pageInfo }} <span class="mx-3">/</span> {{ total }}
    </div>
    <v-layout align-items class="pagination-wrapper">
      <div
        :class="['cell arrow', currentPage === 1 && 'disabled-arrow']"
        @click="() => changePage(currentPage - 1)"
      >
        
      </div>
      <div
        :class="[
          'cell',
          currentPage === page && 'active',
          page === '...' && 'disabled-cell',
        ]"
        v-for="page in cells"
        :key="page"
        @click="() => changePage(page)"
      >
        {{ page }}
      </div>
      <div
        :class="['cell arrow', currentPage === pages && 'disabled-arrow']"
        @click="() => changePage(currentPage + 1)"
      >
        
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    pagination: {
      type: Object,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    currentPage() {
      return _.get(this.pagination, "page", 0);
    },
    rowsPerPage() {
      return _.get(this.pagination, "rowsPerPage", 0);
    },
    pageInfo() {
      const maxPage = Math.min(this.currentPage * this.rowsPerPage, this.total);
      return `${(this.currentPage - 1) * this.rowsPerPage + 1} - ${maxPage}`;
    },
    pages() {
      return Math.ceil(this.total / this.rowsPerPage);
    },
    cells() {
      if (this.pages <= 7) {
        return [...Array(this.pages)].map((_, index) => index + 1);
      } else if (this.currentPage < 5) {
        return [1, 2, 3, 4, 5, "...", this.pages];
      } else if (this.currentPage > this.pages - 4) {
        return [
          1,
          "...",
          this.pages - 4,
          this.pages - 3,
          this.pages - 2,
          this.pages - 1,
          this.pages,
        ];
      } else {
        return [
          1,
          "...",
          this.currentPage - 1,
          this.currentPage,
          this.currentPage + 1,
          "...",
          this.pages,
        ];
      }
    },
  },
  methods: {
    changePage(page) {
      console.log("PAGE", page);
      this.$set(this.pagination, "page", page);
      this.$emit("update:pagination", this.pagination);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-info {
  color: black;
  margin-right: 24px;
}
.arrow {
  font-family: "b-icons";
  font-size: 11px;
  font-weight: 600;
  min-width: 41px !important;
}
.pagination-wrapper {
  border: 1px solid #e6e6e6;
  border-radius: 0.25rem;
}
.active {
  color: black !important;
  font-weight: 600 !important;
  background-color: #e6e6e6;
}
.disabled-arrow {
  color: rgb(230, 230, 230) !important;
  cursor: default !important;
  pointer-events: none;
}
.disabled-cell {
  cursor: default !important;
  color: rgb(137, 137, 137) !important;
  pointer-events: none;
}
.cell {
  min-width: 32px;
  min-height: 32px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  text-align: center;
  cursor: pointer;
  color: rgb(137, 137, 137);
}
.cell:hover {
  color: black;
}
.cell:first-child {
  border-right: 1px solid #e6e6e6;
}
.cell:last-child {
  border-left: 1px solid #e6e6e6;
}
</style>